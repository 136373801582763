<template>
    <div class="producthead">
                <img class="position" src="../assets/img/address.png" alt=""><span class="color">{{titles.title}}</span>
                 <span v-if='catechild!=0'>>
                    <router-link :to="titles.url1" v-if="titles.url1"><span  :class="head2==1?'color':''">{{titles.title1}}</span></router-link>
                    <router-link :to="titles.url2" v-if="titles.url2"><span   :class="head2==2?'color':''">{{titles.title2}}</span></router-link>
                    <router-link :to="titles.url3" v-if="titles.url3"><span  :class="head2==3?'color':''">{{titles.title3}}</span></router-link>     
                 </span>
   </div>
</template>
<style>

.producthead{font-size: 14px;    padding: 10px 20px;    margin-top: 65px;  border-top: 1px solid #ddd; border-bottom: 1px solid #ddd;  color: #666;background: #fff;}
.producthead .position{width: 16px;height: 20px;    vertical-align: middle;}
.producthead span{padding: 10px;}
</style>
<script>

  export default {
      name: 'Head2',
      props: {
           head2: {
                type: String,
                default: '1'
           },
           catechild: {
                type: String,
                default: '0'
           },
           titles: {
                type: Object,
                default:function() {
                     return {
                          'title':'功能',
                          'title1':'概况','url1':'product',
                          'title2':'全部功能','url2':'allfunction',
                          'title3':'配套硬件','url3':'yjdetail',
                     }
                }
           },
      },
  }
</script>