<template>
<div class="product yjdetail" >
 <Header url="product"/>
 <Head2 head2='3' catechild="1" />

    <div class="pbox goodslist">
          <div class="w12 clearfix">
                    <div class="yjtitle">配套硬件</div>
                    <div class="yjzimu">Supporting hardware</div>
                    <div class="linex2"> </div>

                    <div class="item2" v-for="(item,index) in list" :key="index">
                            <div class="goodsimg" ><img :src="item.img_url" alt=""></div>
                            <div class="goodsinfo">
                                     <div class="gtitle">{{item.title}}</div>        
                                     <div class="gdesc">{{item.desc}}</div>        
                            </div>
                    </div>

          </div>

   </div>
  <Footer />
  </div>
</template>

<script>
import '../../assets/css/index.css'
import Header from '../../components/header'
import Head2 from '../../components/head2'

import Footer from '../../components/footer'
import {gongnengYJ}  from '../../request/api'
export default {
    name: 'Yjdetail',
    components: {        Header,        Head2,        Footer,    },
    data() {
      return {
              list:[]
      }
    },
    mounted(){
       this.getYingjian()
    },
    methods: {
        getYingjian(){
                let that = this;
                gongnengYJ().then(res=>{
                        that.list = res.data
                })
        }
    }


}
</script>

